
import { defineComponent } from 'vue';
import PlaceholderSelect from '@/components/forms/components/PlaceholderSelect.vue';
import toolbarOptions from '@/utils/toolbars';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import { Template } from '@/models/TemplateModel';

const TINY_MCE_API_KEY = 'm90gcfxe9n3uual8tazg8h2yiaq3pu9fumvhvh94wnrpl91t';

const initValues = {
  subject: '',
  body: '',
} as {} as unknown as Template;

export default defineComponent({
  name: 'template-editor-form',
  emits: ['formSubmit', 'updateTemplate', 'createTemplate'],
  components: {
    Editor,
    PlaceholderSelect,
  },
  props: {
    template: {
      type: Object,
    },
    placeholderOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    pageLoading: {
      type: Boolean,
    },
    formSubmitted: {
      type: Boolean,
    },
    showPlaceholders: {
      type: Boolean,
      default: true,
    },
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
  },
  data: () => ({
    api_key: TINY_MCE_API_KEY,
    formData: initValues,
    currentCursorSelection: 0,
    subjectInput: {} as typeof HTMLInputElement,
    updateMode: false,
    previewModel: '',
    isEdit: '',
    currentlyActiveField: '',
    config: {
      destroy: true,
      selector: 'textarea',
      auto_focus: 'input',
      menubar: '',
      toolbar: toolbarOptions.join(' '),
      height: '500',
      plugins: 'code',
      contextmenu: 'link image table',
    },
  }),
  mounted() {
    this.updateMode = false;

    this.subjectInput = this.$refs.subjectInput as typeof HTMLInputElement;

    this.initForm();
  },
  methods: {
    initForm() {
      this.formData = {} as unknown as Template;
    },
    updateTemplate() {
      this.$emit('updateTemplate', this.formData);
    },
    createTemplate() {
      this.$emit('createTemplate', this.formData);
    },
    handleElementFocus($event, field) {
      this.currentlyActiveField = field;

      if (field == 'subjectInput') {
        this.currentCursorSelection = $event.target.selectionStart ?? 0;
      }
    },
    handleElementCursor($event) {
      this.currentCursorSelection = $event.target.selectionStart ?? 0;
    },
    handlePlaceholderChange(content) {
      if (!this.currentlyActiveField) return;

      if (!content) return;

      const placeholder = `[${content}]`;

      if (this.currentlyActiveField == 'editorInput') {
        getTinymce().activeEditor.insertContent(placeholder);
      }

      if (this.currentlyActiveField == 'subjectInput') {
        const subjectValue = this.formData.subject ?? '';

        const stringValuePreCursor =
          subjectValue !== ''
            ? subjectValue.slice(0, this.currentCursorSelection)
            : '';

        const stringValuePosCursor =
          subjectValue !== ''
            ? subjectValue.slice(
                this.currentCursorSelection,
                subjectValue.length
              )
            : '';

        this.formData.subject =
          stringValuePreCursor + placeholder + stringValuePosCursor;
      }
    },
    handlePlaceholderClose() {
      return false;
    },
    handleSubmitForm() {
      return this.$emit('formSubmit', this.formData);
    },
  },
  watch: {
    template(values) {
      this.updateMode = false;

      if (typeof values === 'object' && typeof values.id !== 'undefined') {
        this.updateMode = true;
        this.formData = values;
      }
    },
  },
});
